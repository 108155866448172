*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html, body, #root {
  height: 100%; }

*,
*::before,
*::after {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@keyframes spin {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }

.layout__control, .layout__control--dark {
  flex-grow: 1;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  transition: .3s; }
  @media only screen and (max-width: 42.5em) {
    .layout__control, .layout__control--dark {
      padding: 20px 16px; } }

.layout {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .layout__control {
    background-color: #F8F8FB; }
    .layout__control--container {
      width: 650px; }
    .layout__control--dark {
      background-color: #272727; }

.nav, .nav--dark {
  display: flex;
  justify-content: space-between;
  padding: 14px 40px;
  transition: .3s; }
  @media only screen and (max-width: 42.5em) {
    .nav, .nav--dark {
      padding: 10px 16px; } }

.nav {
  border-bottom: 1px solid #efefef; }
  .nav__icon {
    width: 20px;
    height: 20px;
    flex: 1; }
  .nav__title {
    display: flex;
    align-items: center; }
    .nav__title svg {
      margin-right: 10px; }
    @media only screen and (max-width: 27.5em) {
      .nav__title--text {
        display: none; } }
  .nav--dark {
    background-color: black;
    border-bottom: 1px solid #424242; }
  .nav__title--dark {
    display: flex;
    align-items: center;
    color: white; }
    .nav__title--dark svg {
      margin-right: 10px;
      fill: white; }

.info-block, .info-block--dark {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  border-bottom: 1px solid #efefef;
  transition: .3s; }
  @media only screen and (max-width: 42.5em) {
    .info-block, .info-block--dark {
      padding: 20px 16px 40px 16px;
      text-align: center; } }

.info-block__creators, .info-block__creators--dark {
  padding: 14px 50px;
  display: flex;
  justify-content: flex-end;
  transition: .3s; }
  @media only screen and (max-width: 42.5em) {
    .info-block__creators, .info-block__creators--dark {
      justify-content: center;
      padding: 10px 16px; } }

.info-block__creator-link, .info-block__creator-link:link, .info-block__creator-link:visited, .info-block__creator-link--dark, .info-block__creator-link--dark:link, .info-block__creator-link--dark:visited, --dark.info-block__creator-link:link, --dark.info-block__creator-link:link:visited, --dark.info-block__creator-link:visited {
  text-decoration: none;
  position: relative;
  color: #F5F5F5;
  transition: .3s; }

.info-block {
  background-color: white;
  border-bottom: 1px solid #efefef; }
  .info-block__creators--dark {
    background-color: #1d1d1d;
    color: white; }
  .info-block__creator {
    font-weight: 700; }
  .info-block__creator-link, .info-block__creator-link:link, .info-block__creator-link:visited {
    color: #272727; }
    .info-block__creator-link--dark, .info-block__creator-link--dark:link, .info-block__creator-link--dark:visited, .info-block__creator-link:link--dark, .info-block__creator-link:link--dark:link, .info-block__creator-link:link--dark:visited, .info-block__creator-link:visited--dark, .info-block__creator-link:visited--dark:link, .info-block__creator-link:visited--dark:visited {
      color: #e9e9e9; }
  .info-block__creator:hover .info-block__creator-link::after {
    width: 80%;
    transition: .3s; }
  .info-block__creator-link::after {
    content: "";
    width: 0%;
    position: absolute;
    transition: .3s;
    text-align: center;
    top: 24px;
    left: 2px;
    border-bottom: 1px solid black; }
  .info-block__creator:hover .info-block__creator-link--dark::after {
    width: 80%;
    transition: .3s; }
  .info-block__creator-link--dark::after {
    content: "";
    width: 0%;
    position: absolute;
    transition: .3s;
    text-align: center;
    top: 24px;
    left: 2px;
    border-bottom: 1px solid white; }
  .info-block__container {
    width: 650px; }
  .info-block__title {
    margin-bottom: 20px; }
  .info-block__text {
    color: #3A3A3F; }

.info-block--dark {
  background-color: #1d1d1d;
  border-bottom: 1px solid #424242; }

.info-block__title--dark {
  margin-bottom: 20px;
  color: #e9e9e9; }

.info-block__text--dark {
  color: #e9e9e9; }

.control__input-container, .control__input-container--dark {
  width: 100%;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 42.5em) {
    .control__input-container, .control__input-container--dark {
      flex-direction: column; } }

.control__panel-container {
  position: relative; }

.control__button-container {
  display: flex; }

.control__input-container--dark {
  color: white; }
  .control__input-container--dark--input {
    width: 65%; }
    @media only screen and (max-width: 42.5em) {
      .control__input-container--dark--input {
        width: 100%; } }
  .control__input-container--dark--radio {
    width: 35%;
    margin-bottom: 28px; }
    @media only screen and (max-width: 42.5em) {
      .control__input-container--dark--radio {
        width: 100%; } }

.control__input-container--input {
  width: 65%; }
  @media only screen and (max-width: 42.5em) {
    .control__input-container--input {
      width: 100%; } }

.control__input-container--radio {
  width: 35%;
  margin-bottom: 28px; }
  @media only screen and (max-width: 42.5em) {
    .control__input-container--radio {
      width: 100%; } }

.code-block, .code-block--dark {
  padding: 10px;
  color: white;
  border-radius: 12px;
  position: relative;
  transition: .3s;
  margin-bottom: 40px; }

.code-block__code, .code-block__code--dark {
  font-family: monospace;
  font-size: 16px;
  line-height: 24px; }

.code-block {
  background: linear-gradient(to bottom, #3f3f3f 0%, #383838 33%, #262626 100%);
  box-shadow: 0px 0px 50px -10px #787878; }
  .code-block--dark {
    background: linear-gradient(to bottom, white 0%, #ebebeb 33%, #d4d4d4 100%);
    box-shadow: 0px 0px 50px -10px #242424; }
  .code-block__button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px; }
  .code-block__buttons-effect {
    display: flex; }
  .code-block__button-effect--container {
    position: absolute;
    top: 15px;
    left: 15px; }
  .code-block__button-effect {
    margin-right: 6px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #efefef;
    border-radius: 5px; }
    .code-block__button-effect--red {
      background-color: #ff2441; }
    .code-block__button-effect--yellow {
      background-color: #ffde24; }
    .code-block__button-effect--green {
      background-color: #00c732; }
  .code-block__code {
    color: #dbdbdb; }
    .code-block__code--dark {
      color: #262626; }
  .code-block__code-comment {
    font-family: monospace;
    color: #838383; }
  .code-block__component-start-name {
    font-family: monospace;
    font-style: italic;
    color: #838383; }
  .code-block__component-name {
    font-family: monospace;
    font-style: normal;
    color: #00c732; }

pre {
  padding-left: 20px;
  margin-bottom: 40px;
  white-space: pre-wrap;
  overflow-x: auto; }
  @media only screen and (max-width: 27.5em) {
    pre {
      padding: 8px; } }

.button {
  padding: 12px 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: .3s;
  border: none;
  font-size: 16px;
  outline: none; }
  @media only screen and (max-width: 27.5em) {
    .button {
      padding: 16px; } }

.button:hover {
  cursor: pointer; }

.button:not(:last-child) {
  margin-right: 16px; }

.button__primary {
  min-width: 120px;
  background-color: #F5F5F5; }
  @media only screen and (max-width: 27.5em) {
    .button__primary {
      min-width: 0%; } }
  .button__primary:hover {
    box-shadow: 0px 4px 20px -5px rgba(68, 68, 68, 0.753);
    transform: translateY(-2px); }
  .button__primary:active {
    transform: translateY(1px); }
  .button__primary .button__icon {
    fill: black; }

.button__primary--dark {
  min-width: 120px;
  background-color: #272727;
  color: white; }
  @media only screen and (max-width: 27.5em) {
    .button__primary--dark {
      min-width: 0%; } }
  .button__primary--dark:hover {
    box-shadow: 0px 4px 20px -5px rgba(68, 68, 68, 0.753);
    transform: translateY(-2px); }
  .button__primary--dark:active {
    transform: translateY(1px); }
  .button__primary--dark .button__icon {
    fill: white; }

.button__secondary {
  background-color: #008CE9;
  color: white; }
  .button__secondary .button__icon {
    fill: white; }
  .button__secondary:hover:enabled {
    box-shadow: 0px 4px 20px -5px rgba(11, 17, 31, 0.753);
    transform: translateY(-2px); }
  .button__secondary:active:enabled {
    transform: translateY(1px); }
  .button__secondary:disabled {
    background-color: #61636A;
    color: #3A3A3F;
    cursor: not-allowed; }
    .button__secondary:disabled .button__icon {
      fill: #3A3A3F; }

.button__secondary--dark {
  background-color: #008CE9;
  color: white; }
  .button__secondary--dark .button__icon {
    fill: white; }
  .button__secondary--dark:hover:enabled {
    box-shadow: 0px 4px 20px -5px rgba(11, 17, 31, 0.753);
    transform: translateY(-2px); }
  .button__secondary--dark:active:enabled {
    transform: translateY(1px); }
  .button__secondary--dark:disabled {
    background-color: #c7c7c7;
    color: #9e9e9e;
    cursor: not-allowed; }
    .button__secondary--dark:disabled .button__icon {
      fill: #9e9e9e; }

.button__tertiary {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 1px #008CE9;
  color: #008CE9; }
  .button__tertiary:hover:enabled .button__icon {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 49%; }
  .button__tertiary .button__icon {
    fill: #008CE9; }
  .button__tertiary:disabled {
    box-shadow: inset 0px 0px 0px 0px #008CE9;
    background-color: #61636A;
    color: #3A3A3F;
    cursor: not-allowed; }
    .button__tertiary:disabled .button__icon {
      fill: #3A3A3F; }

.button__tertiary--dark {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 1px #008CE9;
  color: #008CE9; }
  .button__tertiary--dark .button__icon {
    fill: #008CE9; }
  .button__tertiary--dark:hover:enabled .button__icon {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 49%; }
  .button__tertiary--dark:disabled {
    box-shadow: inset 0px 0px 0px 0px #008CE9;
    background-color: #c7c7c7;
    color: #9e9e9e;
    cursor: not-allowed; }
    .button__tertiary--dark:disabled .button__icon {
      fill: #9e9e9e; }

.button__icon {
  margin-right: 10px; }
  @media only screen and (max-width: 27.5em) {
    .button__icon {
      margin-right: 0px; } }

@media only screen and (max-width: 27.5em) {
  .button__text {
    display: none; } }

.input-field__input, .input-field__input--dark, .input-field__input--dark--error, .input-field__input--error {
  padding: 14px 20px;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 10px;
  outline: none; }

.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;
  margin-right: 20px;
  position: relative; }
  .input-field__label {
    margin-bottom: 14px; }
  .input-field__check {
    position: absolute;
    top: 44px;
    right: 20px; }
  .input-field__input {
    border: 1px solid #dadada; }
    .input-field__input--dark {
      border: 1px solid #424242;
      background-color: #1d1d1d;
      color: white; }
      .input-field__input--dark--error {
        color: white;
        background-color: #1d1d1d;
        border: 1px solid #c50031; }
        .input-field__input--dark--error:focus {
          border: 1px solid #c50031;
          outline: none; }
      .input-field__input--dark:focus {
        outline: none;
        border: 1px solid #008CE9; }
    .input-field__input--error {
      border: 1px solid #c50031; }
      .input-field__input--error:focus {
        border: 1px solid #c50031;
        outline: none; }
    .input-field__input:focus {
      outline: none;
      border: 1px solid #008CE9; }
    .input-field__input::placeholder {
      color: #c5c5c5; }

.choice-toggle__container {
  display: flex; }

.choice-toggle__option {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  user-select: none; }
  .choice-toggle__option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }

.container, .container--dark {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 16px;
  user-select: none; }

.checkmark, .checkmark--dark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  transition: .3s; }

/* Customize the label (the container) */
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.container--dark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom radio button */
.checkmark {
  background-color: white;
  border: 1px solid #ccc; }

.checkmark--dark {
  background-color: #1d1d1d;
  border: 1px solid #424242; }

.label {
  margin-right: 30px;
  font-size: 16px;
  font-weight: 600; }

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border: 1px solid #2196F3; }

.container--dark:hover input ~ .checkmark--dark {
  border: 1px solid #2196F3; }

/* When the radio button is checked, add a blue background */
.container input:checked ~ .label {
  color: #2196F3; }

.container--dark input:checked ~ .label {
  color: #2196F3; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.checkmark--dark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block; }

/* Show the indicator (dot/circle) when checked */
.container--dark input:checked ~ .checkmark--dark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #2196F3; }

.container--dark .checkmark--dark:after {
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #2196F3; }

.info-message {
  display: flex;
  position: absolute;
  font-style: italic;
  font-size: 14px;
  align-items: center; }
  .info-message .info-message__icon--info, .info-message .info-message__icon--error {
    margin-right: 10px; }
  .info-message__icon--info {
    fill: #8B8D91; }
  .info-message__icon--error {
    fill: #e61d38; }
  .info-message__info {
    top: 0;
    left: 170px;
    color: #8B8D91; }
  .info-message__error {
    color: #e61d38;
    top: 86px;
    left: 0; }
